<template>
    <component :is="resolveLayoutVariant">
        <!--    <v-slide-x-transition mode="out-in">-->
        <!--        <transition name="transition" mode="out-in" appear>-->
        <router-view></router-view>
        <!--        </transition>-->
        <!--    </v-slide-x-transition>-->
    </component>
</template>

<script>
import LayoutBlank from '@/layouts/LayoutBlank';
import LayoutContent from '@/layouts/LayoutContent';
import LayoutContentWithSidebar from '@/layouts/LayoutContentWithSidebar.vue';
import LayoutRegister from '@/layouts/LayoutRegister';

export default {
    name: 'App',

    components: {
        LayoutBlank,
        LayoutRegister,
        LayoutContent,
        LayoutContentWithSidebar,
        LayoutBase: () => import('@/layouts/LayoutBase'),
    },
    mounted() {
        // this.check_user()
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || 'ONLINEMED.uz';
            }
        },
    },
    methods: {
        check_user() {
            setTimeout(function () {
                this.$store.dispatch('check_me').then((res) => {
                    if (!res) {
                        if (!['/not-auth', '/', '/login', '/register', '/register/registerer', '/register/region',
                            '/forgot-password', '/new-password', '/password-code', '/privacy-policy', '/terms-of-use', '/not-yet'
                        ].includes(this.$route.path)) {
                            this.$router.push('/not-auth')
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Sizga ruxsat berilmagan',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    }
                })
            }.bind(this), 10)
        }
    },
    computed: {
        resolveLayoutVariant() {
            let route = this.$route
            let token = this.$store.getters.token
            let user = this.$store.getters.registerer
            if (route.meta.layout === 'base') return `layout-base`
            if (route.meta.layout === 'register') return `layout-register`
            if (route.meta.layout === 'content-with-sidebar') return `layout-content-with-sidebar`
            if (route.meta.layout === 'content') return `layout-content`
            if (route.meta.layout === 'blank' || !(token && user && user.viewed && user.accepted)) return 'layout-blank'

            return null
        }
    },
};
</script>
<style>

</style>